<template>
  <div data-aos="fade-up" id="serviços" class="servicos-servicos">
    <div class="servicos-container">
      <h1 class="servicos-text">{{ heading }}</h1>
      <span class="servicos-text01">
        <span>
          A nossa missão consiste em garantir uma qualidade de serviços
          superando as expectativas dos nossos clientes, assegurando
          atempadamente a elaboração de informação adequada à tomada de decisões
          ao nível da gestão, para além de assegurar o cumprimento das
          obrigações legais e fiscais.
        </span>
        <br />
        <br />
        <span>
          Somos uma empresa com experiência comprovada que aposta na inovação
          tecnológica, qualidade, competência, profissionalismo, rigor e
          confidencialidade.
        </span>
      </span>
    </div>
    <div class="servicos-container1">
      <div class="servicos-card-inform">
        <svg viewBox="0 0 1024 1024" class="servicos-icon">
          <path
            d="M170 256v426h684v-426h-684zM854 768h170v86h-1024v-86h170q-34 0-59-26t-25-60v-426q0-34 25-60t59-26h684q34 0 59 26t25 60v426q0 34-25 60t-59 26z"
          ></path>
        </svg>
        <span class="servicos-text06">{{ text }}</span>
        <span class="servicos-text07">{{ text1 }}</span>
      </div>
      <div class="servicos-card-contab">
        <svg viewBox="0 0 1170.2857142857142 1024" class="servicos-icon2">
          <path
            d="M365.714 512v292.571h-146.286v-292.571h146.286zM585.143 219.429v585.143h-146.286v-585.143h146.286zM1170.286 877.714v73.143h-1170.286v-877.714h73.143v804.571h1097.143zM804.571 365.714v438.857h-146.286v-438.857h146.286zM1024 146.286v658.286h-146.286v-658.286h146.286z"
          ></path>
        </svg>
        <span class="servicos-text08">{{ text2 }}</span>
        <span class="servicos-text09">{{ text3 }}</span>
      </div>
      <div class="servicos-card-inform1">
        <svg viewBox="0 0 1024 1024" class="servicos-icon4">
          <path
            d="M170 256v426h684v-426h-684zM854 768h170v86h-1024v-86h170q-34 0-59-26t-25-60v-426q0-34 25-60t59-26h684q34 0 59 26t25 60v426q0 34-25 60t-59 26z"
          ></path>
        </svg>
        <span class="servicos-text10">{{ text4 }}</span>
        <span class="servicos-text11">{{ text5 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Servicos",
  props: {
    heading: {
      type: String,
      default: "O Cliente é a nossa prioridade"
    },
    text: {
      type: String,
      default: "Informática"
    },
    text2: {
      type: String,
      default: "Contabilidade"
    },
    text1: {
      type: String,
      default:
        "Simplifique o mundo digital com a Danidata - a sua chave para o sucesso tecnológico! Entre em contacto hoje e descubra como podemos impulsionar o seu negócio"
    },
    text5: {
      type: String,
      default:
        "Temos uma ampla gama de opções de cobertura, desde seguros de vida até protecção para propriedades e veículos, estamos aqui para oferecer tranquilidade e segurança em todos os aspectos da sua vida. "
    },
    text4: {
      type: String,
      default: "SEguros"
    },
    text3: {
      type: String,
      default:
        "Uma verdadeira contabilidade reflecte a verdadeira imagem da empresa e é esta que mostramos aos nossos clientes, fornecedores, bancos, funcionários e todos os potenciais interessados na informação financeira"
    }
  }
}
</script>

<style scoped>
.servicos-servicos {
  width: 100%;
  height: 865px;
  display: flex;
  position: relative;
  justify-content: flex-start;
  background-color: #9ca2a9;
}
.servicos-container {
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #034561;
}
.servicos-text {
  color: #9a9a9a;
  font-size: 3rem;
  text-align: center;
}
.servicos-text01 {
  color: rgb(197, 197, 197);
  font-size: 20px;
  max-width: var(--dl-size-size-maxwidth);
  align-self: stretch;
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-fourunits);
  text-transform: none;
  text-decoration: none;
}
.servicos-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: 100%;
  align-self: center;
  align-items: flex-start;
  background-size: cover;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1481026469463-66327c86e544?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzM3x8YWJzdHJhY3R8ZW58MHx8fHwxNzAwMTUzNjY2fDA&ixlib=rb-4.0.3&h=1700");
}
.servicos-card-inform {
  flex: 1;
  width: 5%;
  height: 453px;
  display: flex;
  position: relative;
  max-width: auto;
  align-self: center;
  box-shadow: 0px 10px 10px 0px #737373;
  margin-top: 0px;
  min-height: auto;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #87afbe;
}
.servicos-card-inform:hover {
  transform: scale(1.02);
}
.servicos-icon {
  top: var(--dl-space-space-fiveunits);
  fill: var(--dl-color-gray-500);
  left: 0px;
  right: 0px;
  width: var(--dl-size-size-small);
  height: auto;
  margin: auto;
  position: absolute;
  align-self: stretch;
}
.servicos-text06 {
  color: var(--dl-color-gray-500);
  margin: var(--dl-space-space-twounits);
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.servicos-text07 {
  color: var(--dl-color-gray-500);
  font-size: 1rem;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.servicos-card-contab {
  flex: 1;
  width: 335px;
  height: 516px;
  display: flex;
  z-index: 50;
  position: relative;
  max-width: auto;
  align-self: center;
  box-shadow: 0px 10px 10px 0px #737373;
  min-height: auto;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  margin-right: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #87afbe;
}
.servicos-card-contab:hover {
  transform: scale(1.02);
}
.servicos-icon2 {
  top: var(--dl-space-space-fiveunits);
  fill: var(--dl-color-gray-500);
  left: 0px;
  right: 0px;
  width: var(--dl-size-size-small);
  height: auto;
  margin: auto;
  position: absolute;
}
.servicos-text08 {
  color: var(--dl-color-gray-500);
  margin: var(--dl-space-space-twounits);
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.servicos-text09 {
  color: var(--dl-color-gray-500);
  font-size: 1rem;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.servicos-card-inform1 {
  flex: 1;
  width: 5%;
  height: 453px;
  display: flex;
  z-index: 0;
  position: relative;
  max-width: auto;
  align-self: center;
  box-shadow: 0px 10px 10px 0px #737373;
  margin-top: 0px;
  min-height: auto;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #87afbe;
}
.servicos-card-inform1:hover {
  transform: scale(1.02);
}
.servicos-icon4 {
  top: var(--dl-space-space-fiveunits);
  fill: var(--dl-color-gray-500);
  left: 0px;
  right: 0px;
  width: var(--dl-size-size-small);
  height: auto;
  margin: auto;
  position: absolute;
  align-self: stretch;
}
.servicos-text10 {
  color: var(--dl-color-gray-500);
  margin: var(--dl-space-space-twounits);
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}
.servicos-text11 {
  color: var(--dl-color-gray-500);
  font-size: 1rem;
  align-self: center;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
@media (max-width: 1600px) {
  .servicos-container1 {
    height: 100%;
    background-size: cover;
    background-image: url("https://images.unsplash.com/photo-1550353127-b0da3aeaa0ca?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fGJsdWUlMjBhYnN0cmFjdHxlbnwwfHx8fDE3MDAwNjU1MzJ8MA&ixlib=rb-4.0.3&h=1700");
  }
}
@media (max-width: 1440px) {
  .servicos-container {
    width: 372px;
  }
  .servicos-text {
    height: 99px;
    margin-bottom: var(--dl-space-space-fourunits);
    padding-bottom: 0px;
  }
  .servicos-text01 {
    margin: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-unit);
  }
  .servicos-container1 {
    width: 1067px;
    height: 100%;
    max-width: auto;
    padding-right: var(--dl-space-space-threeunits);
  }
  .servicos-card-inform {
    flex: 1;
  }
  .servicos-icon {
    top: var(--dl-space-space-twounits);
  }
  .servicos-text06 {
    width: var(--dl-size-size-xlarge);
  }
  .servicos-card-contab {
    flex: 1;
  }
  .servicos-card-inform1 {
    flex: 1;
    margin-right: 0px;
  }
  .servicos-icon4 {
    top: var(--dl-space-space-twounits);
  }
  .servicos-text10 {
    width: var(--dl-size-size-xlarge);
    margin-top: var(--dl-space-space-fiveunits);
    margin-left: var(--dl-space-space-twounits);
    padding-top: 0px;
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media (max-width: 1024px) {
  .servicos-servicos {
    width: 100%;
    height: 754px;
  }
  .servicos-text01 {
    max-width: 100%;
  }
  .servicos-container1 {
    width: 650px;
    height: 100%;
    padding: var(--dl-space-space-twounits);
    margin-right: 0px;
  }
  .servicos-card-inform {
    width: 30%;
    padding: var(--dl-space-space-twounits);
    max-width: 100%;
    align-items: center;
  }
  .servicos-icon {
    top: 0px;
    padding-top: var(--dl-space-space-unit);
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .servicos-text06 {
    font-size: 1.3rem;
  }
  .servicos-card-contab {
    width: 30%;
    max-width: 100%;
    align-items: center;
  }
  .servicos-icon2 {
    top: 0px;
    padding-top: var(--dl-space-space-unit);
  }
  .servicos-text08 {
    font-size: 1.3rem;
  }
  .servicos-card-inform1 {
    width: 30%;
    padding: var(--dl-space-space-twounits);
    max-width: 100%;
    align-items: center;
  }
  .servicos-icon4 {
    top: 0px;
    padding-top: var(--dl-space-space-unit);
  }
}
@media (max-width: 768px) {
  .servicos-servicos {
    width: 766px;
    height: 953px;
  }
  .servicos-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .servicos-container1 {
    width: 394px;
    flex-flow: column;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .servicos-card-inform {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .servicos-text06 {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .servicos-card-contab {
    width: 100%;
    max-width: 450px;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .servicos-text08 {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-unit);
  }
  .servicos-card-inform1 {
    width: 100%;
    max-width: 100%;
    margin-top: var(--dl-space-space-twounits);
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .servicos-text10 {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media (max-width: 426px) {
  .servicos-servicos {
    width: 425px;
    height: 1673px;
    margin-bottom: 0px;
    flex-direction: column;
  }
  .servicos-container {
    width: 100%;
    height: 486px;
    flex-direction: column;
  }
  .servicos-text {
    font-size: 2rem;
    margin-bottom: 0px;
  }
  .servicos-text01 {
    font-size: 15px;
  }
  .servicos-container1 {
    width: 100%;
    height: 1197px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .servicos-card-inform {
    width: 230px;
  }
  .servicos-text07 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .servicos-card-contab {
    width: 227px;
  }
  .servicos-card-inform1 {
    width: 227px;
    height: 516px;
    z-index: 1;
    margin-bottom: 0px;
  }
}
</style>
