<template>
  <div
    data-aos="fade-up"
    id="contabilidade"
    class="contabilidade-contabilidade"
  >
    <div class="contabilidade-container">
      <span class="contabilidade-text">
        <span>Contabilidade</span>
        <br />
      </span>
      <span class="contabilidade-text03">
        <span>
          ✔ Tratamos de todo o tipo de contabilidades (organizadas e não
          organizadas)
        </span>
        <br />
      </span>
      <span class="contabilidade-text06">
        <span>✔ Contabilidade Geral, Analítica e Orçamental</span>
        <br />
      </span>
      <span class="contabilidade-text09">
        <span>✔ Processamento de Salários</span>
        <br />
      </span>
      <span class="contabilidade-text12">{{ text }}</span>
      <span class="contabilidade-text13">{{ text1 }}</span>
      <span class="contabilidade-text14">{{ text2 }}</span>
      <span class="contabilidade-text15">{{ text3 }}</span>
      <span class="contabilidade-text16">{{ text4 }}</span>
      <span class="contabilidade-text17">{{ text5 }}</span>
      <span class="contabilidade-text18">{{ text6 }}</span>
      <span class="contabilidade-text19">{{ text7 }}</span>
      <span class="contabilidade-text20">{{ text8 }}</span>
    </div>
    <div class="contabilidade-container1"></div>
  </div>
</template>

<script>
export default {
  name: "Contabilidade",
  props: {
    text: {
      type: String,
      default: "✔ Gestão de Recursos humanos"
    },
    text2: {
      type: String,
      default: "✔Consultoria Financeira"
    },
    text6: {
      type: String,
      default: "✔ Assessoria Fiscal"
    },
    text3: {
      type: String,
      default: "✔ Projectos de Investimentos"
    },
    text1: {
      type: String,
      default: "✔ Gestão de Tesouraria e Bancos"
    },
    text4: {
      type: String,
      default: "✔ Gestão de Activos Tangíveis e Intangíveis"
    },
    text5: {
      type: String,
      default: "✔ Recuperação de Escritas"
    },
    text7: {
      type: String,
      default: "✔ Segurança Social, IRS, IRC, IVA, IMI, IUC, etc"
    }
    // text8: {
    //   type: String,
    //   default: "✔Contencioso"
    // }
  }
}
</script>

<style scoped>
.contabilidade-contabilidade {
  width: 100%;
  height: 849px;
  display: flex;
  position: relative;
  max-width: auto;
  min-height: 80vh;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: 4px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #d9d9d9;
}
.contabilidade-container {
  width: 1086px;
  height: 100%;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  padding-left: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
}
.contabilidade-text {
  color: rgb(49, 49, 49);
  font-size: 50px;
  padding-top: var(--dl-space-space-sixunits);
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
  align-self: center;
}
.contabilidade-text03 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  padding-top: var(--dl-space-space-sixunits);
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text06 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text09 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text12 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text13 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text14 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text15 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text16 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text17 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text18 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text19 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-text20 {
  color: rgb(49, 49, 49);
  font-size: 25px;
  margin-bottom: var(--dl-space-space-unit);
  padding-bottom: 0px;
}
.contabilidade-container1 {
  width: 679px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/graph%202-1200h.jpg");
}
@media (max-width: 1600px) {
  .contabilidade-contabilidade {
    padding-right: 4px;
  }
  .contabilidade-container1 {
    width: 616px;
  }
}
@media (max-width: 1440px) {
  .contabilidade-contabilidade {
    padding-right: 0px;
  }
  .contabilidade-container {
    width: 558px;
    padding-left: var(--dl-space-space-unit);
  }
  .contabilidade-text {
    font-size: 45px;
  }
  .contabilidade-text03 {
    font-size: 17px;
  }
  .contabilidade-text06 {
    font-size: 17px;
  }
  .contabilidade-text09 {
    font-size: 17px;
  }
  .contabilidade-text12 {
    font-size: 17px;
  }
  .contabilidade-text13 {
    font-size: 17px;
  }
  .contabilidade-text14 {
    font-size: 17px;
  }
  .contabilidade-text15 {
    font-size: 17px;
  }
  .contabilidade-text16 {
    font-size: 17px;
  }
  .contabilidade-text17 {
    font-size: 17px;
  }
  .contabilidade-text18 {
    font-size: 17px;
  }
  .contabilidade-text19 {
    font-size: 17px;
  }
  .contabilidade-text20 {
    font-size: 17px;
  }
  .contabilidade-container1 {
    width: 695px;
  }
}
@media (max-width: 1024px) {
  .contabilidade-contabilidade {
    height: 1189px;
    padding: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .contabilidade-container {
    width: 990px;
    height: 681px;
    align-self: center;
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 21px;
    justify-content: flex-end;
  }
  .contabilidade-container1 {
    width: 990px;
    height: 495px;
    border-width: 0px;
  }
}
@media (max-width: 768px) {
  .contabilidade-contabilidade {
    width: 767px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contabilidade-container {
    width: 766px;
    height: 712px;
  }
  .contabilidade-container1 {
    width: 746px;
    height: 487px;
    border-width: 0px;
  }
}
@media (max-width: 426px) {
  .contabilidade-contabilidade {
    width: 100%;
    height: 980px;
    padding-top: 11px;
    padding-left: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contabilidade-container {
    width: 100%;
    height: 855px;
    margin-bottom: 0px;
  }
  .contabilidade-text {
    color: rgb(49, 49, 49);
    width: 90%;
    font-size: 30px;
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    padding-top: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .contabilidade-text03 {
    width: 90%;
    margin-top: var(--dl-space-space-unit);
    padding-top: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .contabilidade-text06 {
    width: 90%;
  }
  .contabilidade-text09 {
    width: 90%;
  }
  .contabilidade-text12 {
    width: 90%;
  }
  .contabilidade-text13 {
    width: 90%;
  }
  .contabilidade-text14 {
    width: 90%;
  }
  .contabilidade-text15 {
    width: 90%;
  }
  .contabilidade-text16 {
    width: 90%;
  }
  .contabilidade-text17 {
    width: 90%;
  }
  .contabilidade-text18 {
    width: 90%;
  }
  .contabilidade-text19 {
    width: 90%;
  }
  .contabilidade-text20 {
    width: 90%;
  }
  .contabilidade-container1 {
    width: 425px;
    height: 467px;
    border-width: 0px;
  }
}
</style>
