<template>
  <div data-aos="fade-up" id="informatica" class="informatica-informatica">
    <h1 class="informatica-text">
      <span>Informática</span>
      <br />
    </h1>
    <span class="informatica-text03">{{ text }}</span>
    <div class="informatica-features">
      <div class="informatica-container">
        <div class="informatica-feature-card">
          <svg viewBox="0 0 877.7142857142857 1024" class="informatica-icon">
            <path
              d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM877.714 449.714v126.857c0 8.571-6.857 18.857-16 20.571l-105.714 16c-6.286 18.286-13.143 35.429-22.286 52 19.429 28 40 53.143 61.143 78.857 3.429 4 5.714 9.143 5.714 14.286s-1.714 9.143-5.143 13.143c-13.714 18.286-90.857 102.286-110.286 102.286-5.143 0-10.286-2.286-14.857-5.143l-78.857-61.714c-16.571 8.571-34.286 16-52 21.714-4 34.857-7.429 72-16.571 106.286-2.286 9.143-10.286 16-20.571 16h-126.857c-10.286 0-19.429-7.429-20.571-17.143l-16-105.143c-17.714-5.714-34.857-12.571-51.429-21.143l-80.571 61.143c-4 3.429-9.143 5.143-14.286 5.143s-10.286-2.286-14.286-6.286c-30.286-27.429-70.286-62.857-94.286-96-2.857-4-4-8.571-4-13.143 0-5.143 1.714-9.143 4.571-13.143 19.429-26.286 40.571-51.429 60-78.286-9.714-18.286-17.714-37.143-23.429-56.571l-104.571-15.429c-9.714-1.714-16.571-10.857-16.571-20.571v-126.857c0-8.571 6.857-18.857 15.429-20.571l106.286-16c5.714-18.286 13.143-35.429 22.286-52.571-19.429-27.429-40-53.143-61.143-78.857-3.429-4-5.714-8.571-5.714-13.714s2.286-9.143 5.143-13.143c13.714-18.857 90.857-102.286 110.286-102.286 5.143 0 10.286 2.286 14.857 5.714l78.857 61.143c16.571-8.571 34.286-16 52-21.714 4-34.857 7.429-72 16.571-106.286 2.286-9.143 10.286-16 20.571-16h126.857c10.286 0 19.429 7.429 20.571 17.143l16 105.143c17.714 5.714 34.857 12.571 51.429 21.143l81.143-61.143c3.429-3.429 8.571-5.143 13.714-5.143s10.286 2.286 14.286 5.714c30.286 28 70.286 63.429 94.286 97.143 2.857 3.429 4 8 4 12.571 0 5.143-1.714 9.143-4.571 13.143-19.429 26.286-40.571 51.429-60 78.286 9.714 18.286 17.714 37.143 23.429 56l104.571 16c9.714 1.714 16.571 10.857 16.571 20.571z"
            ></path>
          </svg>
          <h2 class="informatica-text04">
            <span>Assistência Técnica</span>
            <br />
          </h2>
        </div>
        <div class="informatica-feature-card1">
          <svg viewBox="0 0 961.6822857142856 1024" class="informatica-icon2">
            <path
              d="M219.429 841.143c0-20-16.571-36.571-36.571-36.571s-36.571 16.571-36.571 36.571 16.571 36.571 36.571 36.571 36.571-16.571 36.571-36.571zM587.429 601.143l-389.714 389.714c-13.143 13.143-32 21.143-51.429 21.143s-38.286-8-52-21.143l-60.571-61.714c-13.714-13.143-21.714-32-21.714-51.429s8-38.286 21.714-52l389.143-389.143c29.714 74.857 89.714 134.857 164.571 164.571zM949.714 352.571c0 18.857-6.857 42.286-13.143 60.571-36 101.714-133.714 172-241.714 172-141.143 0-256-114.857-256-256s114.857-256 256-256c41.714 0 96 12.571 130.857 36 5.714 4 9.143 9.143 9.143 16 0 6.286-4 12.571-9.143 16l-167.429 96.571v128l110.286 61.143c18.857-10.857 151.429-94.286 162.857-94.286s18.286 8.571 18.286 20z"
            ></path>
          </svg>
          <h2 class="informatica-text07">{{ heading }}</h2>
        </div>
        <div class="informatica-feature-card2">
          <svg viewBox="0 0 877.7142857142857 1024" class="informatica-icon4">
            <path
              d="M438.857 438.857c172 0 344.571-30.857 438.857-97.143v97.143c0 80.571-196.571 146.286-438.857 146.286s-438.857-65.714-438.857-146.286v-97.143c94.286 66.286 266.857 97.143 438.857 97.143zM438.857 877.714c172 0 344.571-30.857 438.857-97.143v97.143c0 80.571-196.571 146.286-438.857 146.286s-438.857-65.714-438.857-146.286v-97.143c94.286 66.286 266.857 97.143 438.857 97.143zM438.857 658.286c172 0 344.571-30.857 438.857-97.143v97.143c0 80.571-196.571 146.286-438.857 146.286s-438.857-65.714-438.857-146.286v-97.143c94.286 66.286 266.857 97.143 438.857 97.143zM438.857 0c242.286 0 438.857 65.714 438.857 146.286v73.143c0 80.571-196.571 146.286-438.857 146.286s-438.857-65.714-438.857-146.286v-73.143c0-80.571 196.571-146.286 438.857-146.286z"
            ></path>
          </svg>
          <h2 class="informatica-text08">{{ heading1 }}</h2>
        </div>
        <div class="informatica-feature-card3">
          <svg viewBox="0 0 1097.142857142857 1024" class="informatica-icon6">
            <path
              d="M1024 566.857v-475.429c0-9.714-8.571-18.286-18.286-18.286h-914.286c-9.714 0-18.286 8.571-18.286 18.286v475.429c0 9.714 8.571 18.286 18.286 18.286h914.286c9.714 0 18.286-8.571 18.286-18.286zM1097.143 91.429v621.714c0 50.286-41.143 91.429-91.429 91.429h-310.857c0 48.571 36.571 89.714 36.571 109.714s-16.571 36.571-36.571 36.571h-292.571c-20 0-36.571-16.571-36.571-36.571 0-21.143 36.571-60 36.571-109.714h-310.857c-50.286 0-91.429-41.143-91.429-91.429v-621.714c0-50.286 41.143-91.429 91.429-91.429h914.286c50.286 0 91.429 41.143 91.429 91.429z"
            ></path>
          </svg>
          <h2 class="informatica-text09">{{ heading2 }}</h2>
        </div>
      </div>
    </div>
    <img
      id="software"
      :alt="image_alt"
      :src="image_src"
      class="informatica-image"
    />
  </div>
</template>

<script>
export default {
  name: "Informatica",
  props: {
    image_alt: {
      type: String,
      default: "image"
    },
    heading2: {
      type: String,
      default: "Venda de Equipamentos"
    },
    heading1: {
      type: String,
      default: "Venda de Software"
    },
    heading: {
      type: String,
      default: "Reparações"
    },
    image_src: {
      type: String,
      default: "/eticadata-software-logo-verdedata-clear-1000h.png"
    },
    text: {
      type: String,
      default: "Providenciamos todas as suas necessidades tecnológicas"
    }
  }
}
</script>

<style scoped>
.informatica-informatica {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: 168px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  background-color: #d9d9d9;
}
.informatica-text {
  color: #565656;
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-twounits);
}
.informatica-text03 {
  color: var(--dl-color-gray-700);
  width: 70%;
  font-size: 1.55rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-threeunits);
}
.informatica-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: 100%;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.informatica-container {
  width: 100%;
  height: 243px;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.informatica-feature-card {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.informatica-icon {
  fill: #78797d;
  width: 55px;
  height: auto;
}
.informatica-text04 {
  color: rgb(56, 56, 56);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.informatica-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
  padding-bottom: 32px;
}
.informatica-icon2 {
  fill: #78797d;
  width: 55px;
  height: auto;
}
.informatica-text07 {
  color: #383838;
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.informatica-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
  padding-bottom: 32px;
}
.informatica-icon4 {
  fill: #78797d;
  width: 55px;
  height: auto;
}
.informatica-text08 {
  color: rgb(56, 56, 56);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.informatica-feature-card3 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
  justify-content: flex-start;
}
.informatica-icon6 {
  fill: #78797d;
  width: 55px;
  height: auto;
}
.informatica-text09 {
  color: rgb(56, 56, 56);
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.informatica-image {
  width: 1698px;
  height: 933px;
  object-fit: cover;
}
@media (max-width: 1600px) {
  .informatica-image {
    width: 1488px;
    height: 739px;
  }
}
@media (max-width: 1440px) {
  .informatica-informatica {
    height: 1048px;
    position: relative;
  }
  .informatica-features {
    width: 1199px;
    height: 342px;
    align-self: center;
  }
  .informatica-image {
    width: 1165px;
    height: 377px;
  }
}
@media (max-width: 1024px) {
  .informatica-informatica {
    height: 1256px;
  }
  .informatica-text {
    text-align: center;
  }
  .informatica-text03 {
    text-align: center;
  }
  .informatica-features {
    width: 990px;
    height: 326px;
    align-self: center;
    margin-bottom: 181px;
    padding-bottom: 0px;
  }
  .informatica-container {
    grid-template-columns: 1fr 1fr;
  }
  .informatica-image {
    width: 798px;
    height: 304px;
  }
}
@media (max-width: 768px) {
  .informatica-informatica {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .informatica-text {
    text-align: center;
  }
  .informatica-features {
    padding-top: 0px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .informatica-image {
    width: 581px;
    height: 279px;
  }
}
@media (max-width: 426px) {
  .informatica-informatica {
    height: 1349px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .informatica-features {
    height: 884px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    margin-bottom: 0px;
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .informatica-container {
    height: 899px;
    grid-template-columns: 1fr;
  }
  .informatica-image {
    width: 386px;
    height: 258px;
  }
}
</style>
