<template>
  <main class="home-container">
    <header id="nav" data-role="Header" class="home-header">
      <img
        alt="logo"
        src="/logo%20danidata_nobg-1500w.png"
        class="home-image"
      />
      <div class="home-nav">
        <navigation-links rootClassName="rootClassName17"></navigation-links>
      </div>
      <div data-role="BurgerMenu" class="home-burger-menu">
        <svg viewBox="0 0 1024 1024" class="home-icon">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
          ></path>
        </svg>
      </div>
      <div data-role="MobileMenu" class="home-mobile-menu">
        <router-link to="/">
          <svg viewBox="0 0 1024 1024" class="home-icon2">
            <path
              d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
            ></path>
          </svg>
        </router-link>
        <div class="home-nav1">
          <div class="home-container01">
            <img
              alt="image"
              src="/logo%20danidata_nobg-1500w.png"
              class="home-image1"
            />
            <div
              data-role="CloseMobileMenu"
              class="home-close-mobile-menu"
            ></div>
          </div>
          <navigation-links rootClassName="rootClassName18"></navigation-links>
        </div>
      </div>
    </header>
    <div class="home-hero"><div class="home-hero1"></div></div>
    <app-servicos></app-servicos>
    <app-contabilidade></app-contabilidade>
    <app-seguros></app-seguros>
    <app-informatica></app-informatica>
    <div id="contactos" class="home-contacte">
      <h1 class="home-banner-heading heading2">
        Contacte-nos para um atendimento personalizado às suas necessidades
      </h1>
      <form
        action="https://formsubmit.co/cb8e25fb2884936ea42166b4f0fcee60"
        method="POST"
        enctype="multipart/form-data"
        class="home-form"
      >
        <div class="home-container02">
          <div class="home-container03">
            <div class="home-container04">
              <label class="home-text">Nome:</label>
              <label class="home-text01">
                <span>Sobrenome</span>
                <span>:</span>
              </label>
            </div>
            <label class="home-text04">
              <span>Email</span>
              <span>:</span>
            </label>
          </div>
          <div class="home-container05">
            <div class="home-container06">
              <div class="home-container07">
                <input
                  type="text"
                  name="name"
                  placeholder="Nome"
                  class="home-textinput input"
                  required
                />
              </div>
              <input
                type="text"
                name="sobrenome"
                placeholder="Sobrenome"
                class="home-textinput1 input"
                required
              />
            </div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              class="home-textinput2 input"
              required
            />
          </div>
        </div>
        <div class="home-container08">
          <label class="home-text07">Mensagem</label>
          <input
            type="text"
            name="mensagem"
            placeholder="Mensagem"
            class="home-textinput3 input"
            required
          />
          <button type="submit" class="home-button button">
            <span>Enviar</span>
          </button>
        </div>
      </form>
    </div>
    <div class="home-footer">
      <footer class="home-footer1">
        <div class="home-container09">
          <span class="home-logo">Danidata</span>
        </div>
        <div class="home-container10">
          <div class="home-container11">
            <div class="home-container12">
              <div class="home-container13">
                <div class="home-container14">
                  <div class="home-container15">
                    <span class="home-text09">
                      <span class="home-text10">Morada:</span>
                      <br class="home-text11" />
                      <span class="home-text12">Calçada da Boavista nº13</span>
                      <br class="home-text13" />
                      <span class="home-text14">4815-132 Lordelo,GMR</span>
                      <br />
                    </span>
                  </div>
                </div>
              </div>
              <span class="home-text16">
                <span class="home-text17">Email:</span>
                <br />
                <span class="home-text19">geral@danidata.com</span>
              </span>
            </div>
            <div class="home-container16">
              <span class="home-text20">
                <span class="home-text21">Contacto:</span>
                <br class="home-text22" />
                <span class="home-text23">917111522</span>
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="home-separator"></div>
        <div class="home-container17">
          <span class="home-text25">© 2023 Danidata, All Rights Reserved.</span>
          <div class="home-icon-group"></div>
        </div>
      </footer>
    </div>
  </main>
</template>

<script>
import NavigationLinks from "../components/navigation-links"
import AppServicos from "../components/servicos"
import AppContabilidade from "../components/contabilidade"
import AppSeguros from "../components/seguros"
import AppInformatica from "../components/informatica"

export default {
  name: "Home",
  components: {
    NavigationLinks,
    AppServicos,
    AppContabilidade,
    AppSeguros,
    AppInformatica
  },
  metaInfo: {
    title: "Danidata",
    meta: [
      {
        property: "og:title",
        content: "Danidata"
      }
    ]
  }
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  padding: 0px;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #034561;
}
.home-header {
  width: 100%;
  height: 94px;
  margin: 0px;
  display: flex;
  z-index: 100;
  position: fixed;
  max-width: 100%;
  align-self: flex-end;
  align-items: center;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: #034561;
}
.home-image {
  width: 403px;
  height: 134px;
}
.home-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: flex;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-primary-300);
}
.home-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image1 {
  width: 150px;
  height: 64px;
}
.home-close-mobile-menu {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-hero {
  width: 100%;
  height: 794px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-hero1 {
  width: 100%;
  height: 1156px;
  display: flex;
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #d9d9d9;
  background-image: url("/graph-1500h.jpg");
  background-position: bottom;
}
.home-contacte {
  width: 100%;
  height: 700px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-banner-heading {
  color: var(--dl-color-gray-white);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: 59px;
}
.home-form {
  width: 1293px;
  height: 414px;
  margin: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: #949494;
  border-style: double;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
}
.home-container02 {
  width: 100%;
  height: 146px;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  color: var(--dl-color-gray-500);
  font-size: 27px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text01 {
  color: var(--dl-color-gray-500);
  font-size: 25px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text04 {
  color: var(--dl-color-gray-500);
  font-size: 25px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-textinput {
  width: 361px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: #949494;
}
.home-textinput1 {
  width: 360px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: #949494;
}
.home-textinput2 {
  width: 621px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: #949494;
}
.home-container08 {
  width: 100%;
  height: 193px;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text07 {
  color: var(--dl-color-gray-500);
  font-size: 27px;
  padding-bottom: var(--dl-space-space-unit);
}
.home-textinput3 {
  width: 100%;
  height: 160px;
  margin-bottom: var(--dl-space-space-unit);
  background-color: #949494;
}
.home-button {
  color: #11144c;
  display: flex;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-image: linear-gradient(
    180deg,
    rgb(189, 195, 199) 0%,
    rgb(44, 62, 80) 100%
  );
}
.home-button:hover {
  background-image: linear-gradient(
    90deg,
    rgb(189, 195, 199) 0%,
    rgb(161, 184, 206) 100%
  );
}
.home-footer {
  width: 100%;
  height: 354px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 13px;
  /* margin-bottom: 5px; */
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-footer1 {
  width: 792px;
  height: 340px;
  margin: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.home-container09 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-logo {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
}
.home-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-text09 {
  color: var(--dl-color-gray-700);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-text10 {
  color: var(--dl-color-gray-900);
}
.home-text16 {
  color: var(--dl-color-gray-700);
}
.home-text17 {
  color: var(--dl-color-gray-900);
}
.home-container16 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text20 {
  color: var(--dl-color-gray-700);
}
.home-text21 {
  color: var(--dl-color-gray-900);
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container17 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.home-text25 {
  color: var(--dl-color-secondary-gray500);
  width: 100%;
  height: 100%;
  align-self: flex-end;
  margin-top: 0px;
  text-align: left;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.home-icon-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 1600px) {
  .home-mobile-menu {
    display: none;
  }
  .home-contacte {
    height: 712px;
    padding-top: 0px;
  }
  .home-form {
    height: 426px;
    padding: var(--dl-space-space-unit);
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .home-footer {
    height: 326px;
  }
  .home-footer1 {
    height: 100%;
    padding-top: 0px;
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .home-logo {
    padding-top: var(--dl-space-space-unit);
  }
}
@media (max-width: 1440px) {
  .home-header {
    height: 77px;
  }
  .home-mobile-menu {
    display: none;
  }
  .home-contacte {
    height: 695px;
  }
  .home-footer {
    width: 100%;
    margin-right: 0px;
  }
}
@media (max-width: 1024px) {
  .home-container {
    background-color: #000000;
  }
  .home-image {
    width: 184px;
    height: 72px;
  }
  .home-mobile-menu {
    display: none;
  }
  .home-contacte {
    height: 682px;
  }
  .home-form {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .home-header {
    position: relative;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    top: 0px;
    flex: 1;
    right: 0px;
    width: 100%;
    height: 667px;
    margin: auto;
    display: none;
    position: absolute;
    align-self: center;
    background-color: #0e3140;
  }
  .home-icon2 {
    width: var(--dl-size-size-small);
    height: var(--dl-size-size-small);
    align-self: flex-end;
  }
  .home-nav1 {
    height: 559px;
  }
  .home-container01 {
    width: 705px;
    position: relative;
  }
  .home-image1 {
    width: 755px;
    height: 267px;
    align-self: center;
    margin-right: 3px;
  }
  .home-hero1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-textinput2 {
    width: 503px;
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container10 {
    width: 387px;
    height: 50%;
  }
  .home-text09 {
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-text10 {
    color: var(--dl-color-gray-900);
  }
  .home-text11 {
    color: var(--dl-color-gray-700);
  }
  .home-text12 {
    color: var(--dl-color-gray-700);
  }
  .home-text13 {
    color: var(--dl-color-gray-700);
  }
  .home-text14 {
    color: var(--dl-color-gray-700);
  }
  .home-text17 {
    color: var(--dl-color-gray-900);
  }
  .home-text19 {
    color: var(--dl-color-gray-700);
  }
  .home-container16 {
    padding-left: var(--dl-space-space-halfunit);
  }
  .home-text21 {
    color: var(--dl-color-gray-900);
  }
  .home-text22 {
    color: var(--dl-color-gray-900);
  }
  .home-text23 {
    color: var(--dl-color-gray-700);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container17 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text25 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media (max-width: 426px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-nav {
    display: none;
  }
  .home-mobile-menu {
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 688px;
    margin: auto;
    display: none;
    padding: 16px;
    margin-right: auto;
    justify-content: flex-start;
    background-color: #1e447e;
  }
  .home-icon2 {
    align-self: flex-end;
  }
  .home-nav1 {
    width: 100%;
  }
  .home-container01 {
    width: 100%;
  }
  .home-image1 {
    width: 100%;
    height: 149px;
  }
  .home-hero {
    height: 809px;
  }
  .home-hero1 {
    height: 896px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-contacte {
    height: 678px;
  }
  .home-banner-heading {
    width: 100%;
  }
  .home-container02 {
    height: 194px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: 0px;
  }
  .home-container03 {
    height: 152px;
  }
  .home-container04 {
    height: 107px;
  }
  .home-text {
    font-size: 20px;
  }
  .home-text01 {
    font-size: 20px;
    padding-top: var(--dl-space-space-unit);
  }
  .home-text04 {
    font-size: 20px;
  }
  .home-container05 {
    width: 252px;
  }
  .home-textinput {
    width: 237px;
  }
  .home-textinput1 {
    width: 236px;
  }
  .home-textinput2 {
    width: 100%;
  }
  .home-container08 {
    height: 286px;
  }
  .home-text07 {
    font-size: 20px;
  }
  .home-textinput3 {
    height: 109px;
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-footer {
    width: 425px;
    height: 379px;
  }
  .home-footer1 {
    width: 406px;
    padding: var(--dl-space-space-unit);
  }
  .home-container09 {
    width: 100%;
    height: 277px;
    max-width: 100%;
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container17 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text25 {
    color: var(--dl-color-secondary-gray500);
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
