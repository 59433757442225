<template>
  <div data-aos="fade-up" id="seguros" class="seguros-seguros">
    <h1 class="seguros-text">
      <span>Seguros</span>
      <br />
    </h1>
    <span class="seguros-text3">{{ text }}</span>
    <div class="seguros-container">
      <feature-card31 rootClassName="rootClassName"></feature-card31>
      <feature-card31
        image_src="/fidelidade-200h.png"
        rootClassName="rootClassName4"
      ></feature-card31>
      <feature-card31
        image_src="/external/caravela_logo-200h.png"
        rootClassName="rootClassName3"
      ></feature-card31>
      <feature-card31
        image_src="/external/tranquilidade-200h.png"
        rootClassName="rootClassName5"
      ></feature-card31>
    </div>
    <div class="seguros-container1">
      <feature-card31
        image_src="/external/transferir-200h.png"
        rootClassName="rootClassName12"
      ></feature-card31>
      <feature-card31
        image_src="/external/libertyseguros-200h.png"
        rootClassName="rootClassName13"
      ></feature-card31>
      <feature-card31
        image_src="/external/acordo-vitoria-600x193-1-300x97-200h.png"
        rootClassName="rootClassName14"
      ></feature-card31>
      <feature-card31
        image_src="/logo-mapfre-200h.png"
        rootClassName="rootClassName15"
      ></feature-card31>
    </div>
  </div>
</template>

<script>
import FeatureCard31 from "./feature-card31"

export default {
  name: "Seguros",
  props: {
    text: {
      type: String,
      default: "Todo o tipo de Seguros "
    }
  },
  components: {
    FeatureCard31
  }
}
</script>

<style scoped>
.seguros-seguros {
  width: 100%;
  height: 833px;
  display: flex;
  position: relative;
  max-width: 100%;
  align-items: center;
  padding-top: 141px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  background-color: #e1e1e1;
}
.seguros-text {
  color: #565656;
  font-size: 3rem;
  margin-bottom: var(--dl-space-space-unit);
}
.seguros-text3 {
  color: var(--dl-color-gray-700);
  width: 70%;
  font-size: 1.15rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.seguros-container {
  height: 229px;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  position: relative;
  align-self: stretch;
  border-color: #8a8686;
  border-width: 1px;
  border-top-width: 2px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.seguros-container1 {
  width: auto;
  height: auto;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-self: stretch;
  border-color: #8a8686;
  border-width: 1px;
  margin-bottom: var(--dl-space-space-unit);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media (max-width: 1600px) {
  .seguros-seguros {
    background-color: #e9e9e9;
  }
}
@media (max-width: 1440px) {
  .seguros-container {
    height: auto;
    align-self: center;
  }
  .seguros-container1 {
    align-self: center;
  }
}
@media (max-width: 1024px) {
  .seguros-seguros {
    height: 1159px;
  }
  .seguros-text {
    text-align: center;
  }
  .seguros-text3 {
    text-align: center;
  }
  .seguros-container {
    height: 715px;
    margin-bottom: 0px;
    grid-template-columns: 1fr 1fr;
  }
  .seguros-container1 {
    height: 715px;
    margin-bottom: 0px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .seguros-seguros {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .seguros-text {
    text-align: center;
  }
}
@media (max-width: 426px) {
  .seguros-seguros {
    height: 1771px;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .seguros-container {
    grid-template-columns: 1fr;
  }
  .seguros-container1 {
    height: auto;
    align-self: center;
    grid-template-columns: 1fr;
  }
}
</style>
