<template>
  <nav class="navigation-links-nav" v-bind:class="rootClassName">
    <a href="#serviços" class="navigation-links-link">{{ text }}</a>
    <a href="#contabilidade" class="navigation-links-link1">{{ text1 }}</a>
    <a href="#seguros" class="navigation-links-link2">{{ text2 }}</a>
    <a href="#informatica" class="navigation-links-link3">{{ text3 }}</a>
    <a href="#software" class="navigation-links-link4">{{ text4 }}</a>
    <a href="#contactos" class="navigation-links-link5">{{ text41 }}</a>
  </nav>
</template>

<script>
export default {
  name: 'NavigationLinks',
  props: {
    text41: {
      type: String,
      default: 'Contactos',
    },
    text4: {
      type: String,
      default: 'Software',
    },
    text1: {
      type: String,
      default: 'Contabilidade',
    },
    text: {
      type: String,
      default: 'Serviços',
    },
    text3: {
      type: String,
      default: 'Informática',
    },
    text2: {
      type: String,
      default: 'Seguros',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.navigation-links-nav {
  flex: 0 0 auto;
  height: 19px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-link {
  color: #9bb0b9;
  font-size: 18px;
  transition: 0.3s;
  text-decoration: none;
}
.navigation-links-link:hover {
  color: #3e8da7;
  text-decoration:  none;
}
.navigation-links-link:active {
  text-decoration: underline none;
}
.navigation-links-link1 {
  color: #9bb0b9;
  font-size: 18px;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link1:hover {
  color: #3e8da7;
  text-decoration:  none;
}
.navigation-links-link1:active {
  text-decoration: underline none;
}
.navigation-links-link2 {
  color: #9bb0b9;
  font-size: 18px;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link2:hover {
  color: #3e8da7;
  text-decoration:  none;
}
.navigation-links-link2:active {
  text-decoration: underline none;
}
.navigation-links-link3 {
  color: #9bb0b9;
  font-size: 18px;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link3:hover {
  color: #3e8da7;
  text-decoration:  none;
}
.navigation-links-link3:active {
  text-decoration: underline none;
}
.navigation-links-link4 {
  color: #9bb0b9;
  font-size: 18px;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link4:hover {
  color: #3e8da7;
  text-decoration:  none;
}
.navigation-links-link4:active {
  text-decoration: underline none;
}
.navigation-links-link5 {
  color: #9bb0b9;
  font-size: 18px;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link5:hover {
  color: #3e8da7;
  text-decoration:  none;
}
.navigation-links-link5:active {
  text-decoration: underline none;
}










@media(max-width: 1440px) {
  .navigation-links-link {
    color: #9bb0b9;
  }
  .navigation-links-link1 {
    color: #9bb0b9;
  }
  .navigation-links-link2 {
    color: #9bb0b9;
  }
  .navigation-links-link3 {
    color: #9bb0b9;
  }
  .navigation-links-link4 {
    color: #9bb0b9;
  }
  .navigation-links-link5 {
    color: #9bb0b9;
  }
}
@media(max-width: 768px) {
  .navigation-links-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .navigation-links-link {
    font-size: 25px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link1 {
    font-size: 25px;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link2 {
    font-size: 25px;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link3 {
    font-size: 25px;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link4 {
    font-size: 25px;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link5 {
    font-size: 25px;
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-root-class-name18 {
    align-self: center;
  }
}
@media(max-width: 426px) {
  .navigation-links-root-class-name17 {
    display: none;
  }
}
</style>
