<template>
  <div class="feature-card31-feature-card" v-bind:class="rootClassName">
    <img :alt="image_alt" :src="image_src" class="feature-card31-image" />
  </div>
</template>

<script>
export default {
  name: 'FeatureCard31',
  props: {
    image_src: {
      type: String,
      default: '/allianz-200h.png',
    },
    title: {
      type: String,
      default: 'Lorem ipsum',
    },
    rootClassName: String,
    image_alt: {
      type: String,
      default: 'image',
    },
    description: {
      type: String,
      default: 'Lorem ipsum dolor sit amet, consectetur adipiscing.',
    },
    action: {
      type: String,
      default: 'SEE MORE',
    },
  },
}
</script>

<style scoped>
.feature-card31-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
  justify-content: center;
}
.feature-card31-image {
  width: 200px;
  object-fit: cover;
}
.feature-card31-root-class-name {
  height: var(--dl-size-size-xsmall);
  margin-top: var(--dl-space-space-fourunits);
}







</style>
